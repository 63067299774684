html {
	scroll-behavior: smooth;
}

:root {
	--primary: #d90012 !important;
	--secondary: #535353 !important;
	--secondary-accent: #77000a;
}

.App {
	margin-top: 56px;
}

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
button,
div,
li {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.react-share__ShareButton:focus,
.react-share__ShareButton:active {
	outline: 0;
	box-shadow: none !important;
}

.loading-container {
	height: calc(100vh - 56px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--secondary-accent);
	font-size: 20px;
	font-weight: 500;
}

.item-list {
	border-left: none;
	border-right: none;
}

.side-menu {
	position: absolute;
	z-index: 9999;
	top: 0;
	right: -100%;
	display: none;
	width: 100%;
	height: 100vh;
	transition: 0.3s ease;
}

.product-calification {
	width: 70px;
	height: 30px;
	background-color: rgba(53, 53, 53, 0.85);
	color: #fff;
	font-weight: 500;
	border-radius: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 10px;
	left: 10px;
	font-size: 14px;
}

.valores-card {
	width: 180px;
	height: 180px;
	border: 1px solid var(--secondary);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.ais-SearchBox-input {
	border: 1px solid var(--primary);
	height: 38px;
	max-width: 900px;
	width: 100%;
	border-radius: 10px;
	padding: 0px 10px;
}

.ais-SearchBox-submit {
	display: none;
}

.ais-SearchBox-reset {
	display: none;
}

.ais-SearchBox-form {
	width: 100%;
}

ul {
	list-style-type: none;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 0px;
}

input:focus {
	box-shadow: none;
	outline: none;
}

.accordion-button {
	box-shadow: none !important;
	outline: none !important;
	border-color: none !important;
	background-color: transparent !important;
	padding: 0.5rem 1rem !important;
}

.accordion-button:not(.collapsed) {
	color: #fff !important;
}

.login-card {
	border: none !important;
	border-radius: 20px !important;
	box-shadow: 0px 3px 6px rgb(0 0 0 / 16%), 0px 3px 6px rgb(0 0 0 / 23%);
}

.login-input {
	border-radius: 10px !important;
}

.login-input:focus {
	border: 1px solid var(--secondary-accent) !important;
	box-shadow: 0 0 0 0.2rem rgb(119 0 10 / 25%) !important;
}

.login-header {
	background-color: var(--primary) !important;
	color: #fff;
	font-weight: 500;
	border-radius: 20px 20px 0px 0px !important;
	border-bottom: none !important;
}

.my-account-container {
	height: calc(100vh - 56px);
	width: 100%;
	display: flex;
}

.my-account-container-user-not-active {
	height: calc(100vh - 56px);
	width: 100%;
	color: var(--secondary-accent);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.add-product-btn {
	/* position: absolute; */
	/* bottom: 20px;
	right: 20px; */
	width: 45px;
	height: 45px;
	border-radius: 50%;
	background-color: var(--secondary-accent);
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	box-shadow: 0px 3px 6px rgba(119, 0, 10, 0.16),
		0px 3px 6px rgba(119, 0, 10, 0.23);
	transition: 0.3s ease;
}

.add-product-btn:hover {
	background-color: var(--primary);
	box-shadow: 0px 5px 10px rgba(119, 0, 10, 0.25),
		0px 5px 10px rgba(119, 0, 10, 0.35);
}

.close-btn {
	width: 30px;
	min-width: 30px;
	height: 30px;
	min-height: 30px;
	background-color: rgba(255, 255, 255, 0.2);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: rgba(255, 255, 255, 0.8);
	cursor: pointer;
	transition: 0.3s ease;
}

.modal-footer {
	display: flex;
	align-items: center;
	flex-wrap: unset !important;
	justify-content: flex-end;
	padding: 0.75rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: calc(0.3rem - 1px);
	border-bottom-left-radius: calc(0.3rem - 1px);
}

.close-btn:active {
	transform: scale(0.95);
}

.form-control:focus {
	color: #495057 !important;
	background-color: #fff !important;
	border-color: var(--secondary) !important;
	outline: 0 !important;
	box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 25%) !important;
}

label {
	margin-bottom: 0.2rem !important;
	margin-left: 0.25rem !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff !important;
	border-color: var(--primary) !important;
	background-color: var(--primary) !important;
}

.form-control {
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}

.modal-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba(83, 83, 83, 0.7);
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 20px;
	font-weight: 600;
	border-radius: 17px 17px 19px 19px;
}

.delete-icon {
	color: var(--primary);
}
.delete-icon:hover {
	color: var(--secondary-accent);
}

.edit-icon {
	color: var(--secondary);
}

.edit-icon:hover {
	color: var(--secondary-accent);
}

.no-data-message {
	color: var(--secondary);
	text-align: center;
}

.visibility-switch {
	display: flex;
	justify-content: center;
	cursor: pointer;
}

::after,
::before {
	box-sizing: border-box;
	cursor: pointer;
}

.Toastify__toast-body {
	margin: auto 0;
	padding: 6px;
	width: 100% !important;
}

.forgot-password-link {
	font-size: 12px;
	color: var(--secondary-accent);
	cursor: pointer;
}

.forgot-password-link:hover {
	text-decoration: underline;
	font-weight: 500;
}

.product-view-title {
	font-weight: 500;
	line-height: 18px;
}

.product-section-container {
	width: 100%;
	padding: 0px 8px;
}

.product-section-title {
	font-weight: 500;
	margin-bottom: 0px;
}

.share-btn-icon {
	font-size: 1.5rem;
	color: #fff;
}

.facebook-share-btn {
	background: radial-gradient(
		circle at top right,
		rgb(136, 197, 255) 0%,
		rgba(0, 132, 255, 1) 70%,
		rgba(0, 132, 255, 1) 100%
	);
	color: #fff;
	border-radius: 50px;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.whatsapp-share-btn {
	background-color: #25d366 !important;
	color: #fff;
	border-radius: 50px;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.telegram-share-btn {
	background-color: #0088cc !important;
	color: #fff;
	border-radius: 50px;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-view-scrollable-section {
	overflow: scroll;
	max-height: 145px;
	width: 100%;
	border: 1px solid var(--secondary-accent);
	border-radius: 10px;
	padding-bottom: 0.5rem;
}

.categories-scrollable-section {
	width: 100%;
	overflow: scroll;
	padding-bottom: 0.5rem;
}

.progress-bar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	background-color: var(--primary) !important;
	transition: width 0.6s ease;
}

.filter {
	background-color: #e3e3e3;
	color: var(--secondary-accent);
	border-radius: 10px;
	padding: 5px 15px;
	white-space: nowrap;
	cursor: pointer;
}

.filter.active {
	background-color: var(--secondary-accent);
	color: #fff;
	border-radius: 10px;
	padding: 5px 15px;
}

.categories-container {
	position: sticky;
	top: 50px;
	z-index: 4;
	background-color: rgba(255, 255, 255, 0.85);
	height: 110px;
	display: flex;
	align-items: flex-end;
	margin-top: -45px;
}

.truncated-text {
	text-align: center;
	line-height: 1.2rem;
}

.form-check-label {
	margin-bottom: 0px !important;
	padding-left: 0.25rem !important;
	color: var(--secondary) !important;
	padding-top: 0.15rem;
}

.form-check {
	margin-bottom: 0px !important;
	display: flex !important;
	align-items: center;
}

.text-truncate {
	white-space: nowrap; /* Prevents text from wrapping to the next line */
	overflow: hidden; /* Hides any content that overflows its container */
	text-overflow: ellipsis;
}
