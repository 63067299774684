@media (min-width: 768px) and (max-width: 1023px) {
	.menu-shape {
		clip-path: polygon(
			0% 0%,
			100% 0%,
			100% 60%,
			53% 60%,
			51% 100%,
			20% 100%,
			20% 60%,
			0% 60%
		);
	}

	.menu-options-style {
		margin-left: calc(50% - 100px);
	}
}

@media (min-width: 1024px) and (max-width: 1200px) {
	.menu-shape {
		clip-path: polygon(
			0% 0%,
			100% 0%,
			100% 60%,
			48% 60%,
			46% 100%,
			20% 100%,
			20% 60%,
			0% 60%
		);
	}

	.menu-options-style {
		margin-left: 40%;
	}
}

@media (min-width: 1200px) {
	.menu-shape {
		clip-path: polygon(
			0% 0%,
			100% 0%,
			100% 60%,
			38% 60%,
			36% 100%,
			20% 100%,
			20% 60%,
			0% 60%
		);
	}

	.menu-options-style {
		margin-left: calc(50% - 100px);
	}
}

@media (min-width: 768px) {
	.custom-nav-link {
		padding: 5px 15px;
		margin-right: 10px;
		display: flex;
		align-items: center;
		border-radius: 20px;
		color: #fff;
		line-height: 1.3;
		background-color: var(--secondary-accent);
		cursor: pointer;
	}

	.menu-options-style {
		margin-left: calc(50% - 100px);
	}

	.active-nav-link {
		background-color: #fff;
		font-weight: 500;
		color: var(--secondary-accent);
	}

	.brand-custom-class {
		left: 20%;
	}

	.brand-title {
		font-size: 24;
		font-weight: 500;
	}

	.menu-toggle-btn {
		display: none;
	}

	.hero-trasparent-layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.7) 0%,
			rgba(255, 255, 255, 0.45) 37.5%,
			rgba(255, 255, 255, 0) 66.15%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	.hero-cta-container {
		max-width: 50vw;
		height: 100%;
	}

	.home-hero-title {
		font-size: 45px;
		color: var(--secondary);
		font-weight: bold;
		line-height: 50px;
		max-width: 600;
	}

	.atentention-image {
		max-width: 100%;
		border-radius: 20px;
	}

	.parallax-title {
		color: #fff;
		font-size: 3rem;
		font-weight: 500;
	}

	.horizontal-scroll-cards {
		display: grid;
		grid-gap: 1rem;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		padding-left: 0px;
		padding-right: 0px;
		justify-items: center;
		align-items: center;
	}

	.footer-custom-nav-link {
		padding: 5px 15px;
		margin-right: 10px;
		display: flex;
		align-items: center;
		border-radius: 20px;
		color: #fff;
		line-height: 1.3;
		background-color: #262626;
		cursor: pointer;
	}

	.about-hero-background-layer {
		width: 100%;
		height: 550px;
		display: flex;
		align-items: center;
		background: linear-gradient(
			270.07deg,
			#000000 0.06%,
			rgba(0, 0, 0, 0.95) 23.6%,
			rgba(0, 0, 0, 0.8) 59.61%,
			rgba(0, 0, 0, 0.4) 75.78%,
			rgba(196, 196, 196, 0) 101.7%
		);
	}

	.our-story-title {
		font-size: 45px;
		color: #fff;
		font-weight: bold;
		line-height: 50px;
		text-transform: uppercase;
	}

	.mision-vision-wrapper {
		height: 100%;
		margin-top: -50px;
		display: flex;
		align-items: flex-end;
	}

	.mision-vision-container {
		width: 100%x;
		min-height: 300px;
		max-width: 100%;
		padding: 40px;
		display: flex;
		align-items: center;
		background: linear-gradient(
			180deg,
			rgba(220, 220, 220, 0) 0%,
			rgba(220, 220, 220, 0.5) 55.73%,
			rgba(220, 220, 220, 0.7) 79.17%,
			rgba(220, 220, 220, 1) 100%
		);
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.15));
		backdrop-filter: blur(10px);
		border-radius: 25px;
	}

	.mision-vision-separator {
		position: absolute;
		width: 1px;
		height: 150px;
		background-color: transparent;
		right: 50%;
	}

	.valores-container {
		margin-top: -100px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		height: 350px;
	}

	.valores-grid-scroll {
		display: grid;
		grid-gap: 2rem;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		justify-items: center;
		align-items: center;
		width: 100%;
	}

	.hero-catalogue-layer {
		width: 100%;
		height: 100%;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.5) 45.31%,
			rgba(0, 0, 0, 0.7) 100%
		);
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.hero-catalogue-title {
		text-transform: uppercase;
		color: #fff;
		text-align: center;
		font-size: 55px;
	}

	.ais-SearchBox {
		width: 100%;
		max-width: 500px;
		margin: 20px auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: sticky;
		top: 140px;
	}

	.search-container {
		position: sticky;
		top: 50px;
		z-index: 4;
		background-color: rgba(255, 255, 255, 0.85);
		height: 120px;
	}

	.products-grid-scroll-cards {
		display: grid;
		grid-gap: 2rem;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		padding-left: 0px;
		padding-right: 0px;
		justify-items: center;
		align-items: center;
	}

	.login-title {
		text-align: center;
		color: #fff;
		margin-top: 70px;
		margin-bottom: 40px;
	}

	.left-side-login {
		width: 60vw;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.login-form-container {
		width: 100%;
		max-width: 40vw;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 40px;
	}

	.profile-picture-container {
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background-color: #cccccc;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.profile-picture {
		height: 60px;
		width: 60px;
		border-radius: 50%;
		border: 2px solid var(--primary);
		object-fit: cover;
	}

	.side-options-list-item {
		border-left: none !important;
		border-right: none !important;
		padding-right: 16px !important;
		display: flex;
		align-items: center;
		justify-content: space-between;
		cursor: pointer;
	}

	.side-options-list-item-selected {
		padding: calc(0.75rem - 5px) 1rem calc(0.75rem - 5px) 1rem !important;
		border-left: none !important;
		border-right: none !important;
	}

	.sub-option-selected {
		background-color: rgba(217, 0, 18, 0.2);
		padding: 5px 8px 5px 8px;
		border-radius: 10px;
		color: var(--primary);
		cursor: pointer;
	}

	.flow-item-line {
		height: 15px;
		width: 10px;
		border-left: 2px solid #b4b2b2;
		border-bottom: 2px solid #b4b2b2;
		border-radius: 0 0 0 8px;
	}

	.left-sidebar-container {
		width: 17vw;
		height: calc(100vh - 70px);
		box-shadow: rgb(77 10 160 / 16%) 3px 0px 60px,
			rgb(77 10 160 / 23%) 3px 0px 6px;
	}

	.options-container {
		width: 23vw;
		height: calc(100vh - 70px);
		background-color: rgba(239, 226, 255, 0.4);
	}

	.side-option-selected {
		padding: 0.5rem 1rem;
		cursor: pointer;
		background-color: var(--primary) !important;
		color: #fff !important;
		font-weight: 500;
		box-shadow: 0px 3px 6px rgb(77 10 160 / 16%),
			0px 3px 6px rgb(77 10 160 / 23%);
		z-index: 3;
	}

	.side-option-not-selected {
		padding: 0.5rem 1rem;
		cursor: pointer;
		background-color: #fff;
	}

	.icon-list-container {
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 0.5rem;
	}

	.sub-option-title {
		margin-left: 0.75rem !important;
	}

	.sign-out-btn {
		width: 100%;
		border-radius: 10px !important;
		background-color: #ff2929 !important;
		border: none !important;
		box-shadow: 0px 3px 6px rgba(60, 19, 117, 0.16),
			0px 3px 6px rgba(60, 19, 117, 0.23) !important;
		font-weight: 500 !important;
		transition: 0.3s ease !important;
	}

	.sign-out-btn:hover,
	.sign-out-btn:focus {
		background-color: #ce0000 !important;
		border: none !important;
		box-shadow: 0 0 0 0.2rem #bf4646 !important;
	}

	.sign-out-btn:active {
		background-color: #ce0000;
		transform: scale(0.95);
	}

	.header-tasklist {
		height: 92px;
		padding-top: 40px !important;
		color: #fff;
		font-weight: 500;
		font-size: 22px;
		text-align: center;
		z-index: 5;
		background-color: var(--secondary-accent);
		box-shadow: 0px 3px 60px rgba(217, 0, 18, 0.16),
			0px 3px 6px rgba(217, 0, 18, 0.23);
	}

	.task-placeholder-container {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 20px;
		font-weight: 500;
		color: var(--secondary-accent);
	}

	.task-card {
		cursor: pointer;
		border: none !important;
		border-radius: 20px !important;
		box-shadow: 0px 3px 6px rgb(217 0 18 / 16%), 0px 3px 6px rgb(217 0 0 / 23%);
		transition: 0.4s ease;
	}

	.task-card:hover {
		transform: scale(1.02);
		box-shadow: 0px 6px 8px rgb(217 0 18 / 20%), 0px 6px 8px rgb(217 0 0 / 30%);
	}

	.edit-view-container {
		overflow-y: scroll;
		max-height: calc(100% - 92px);
	}

	.header-dashboard {
		height: 92px;
		color: #fff;
		font-weight: 500;
		font-size: 22px;
		z-index: 6;
		background-color: #561218;
		box-shadow: 0px 3px 60px rgba(217, 0, 18, 0.16),
			0px 3px 6px rgba(217, 0, 18, 0.23);
	}

	.table-header-container {
		height: 100px;
		padding: 0px 15px;
		background: #fff;
		z-index: 5;
		position: fixed;
		width: 60%;
		display: flex;
		align-items: flex-end;
	}

	.products-table-header,
	.user-table-header {
		padding: 8px 10px !important;
		border-radius: 10px !important;
		border: none !important;
		width: 100%;
		z-index: 5;
		box-shadow: 0px 3px 6px rgb(217 0 18 / 16%), 0px 3px 6px rgb(217 0 18 / 23%);
		color: #fff;
		font-weight: 500;
		background-color: var(--primary) !important;
	}

	.users-table-header-container {
		height: 56px;
		padding: 0px 15px;
		background: #fff;
		z-index: 5;
		position: fixed;
		width: 60%;
		display: flex;
		align-items: flex-end;
	}

	.product-item-table {
		flex-direction: row !important;
		justify-content: space-around;
		align-items: center;
		padding: 8px 10px !important;
		border-radius: 10px !important;
		border: none !important;
		box-shadow: 0px 2px 4px rgb(0 0 0 / 15%), 0px 2px 4px rgb(0 0 0 / 20%);
		color: #535353;
		cursor: pointer;
		background-color: #f1f1f1 !important;
		transition: 0.3s ease;
	}

	.product-item-table:hover {
		transform: scale(1.01);
		background-color: #d7d7d7 !important;
		box-shadow: 0px 4px 6px rgb(0 0 0 / 16%), 0px 5px 10px rgb(0 0 0 / 23%);
	}

	.modal-content {
		position: relative !important;
		display: flex !important;
		flex-direction: column !important;
		max-width: 500px !important;
		pointer-events: auto !important;
		background-color: #fff !important;
		background-clip: padding-box !important;
		border: none !important;
		border-radius: 20px !important;
		outline: 0 !important;
	}

	.custom-modal-header {
		background-color: var(--primary);
		color: #fff;
		border-top-left-radius: calc(20px - 2px) !important;
		border-top-right-radius: calc(20px - 2px) !important;
		padding: 0.75rem 1rem !important;
	}

	.modal-dialog {
		align-items: center;
		justify-content: center;
	}

	.add-edit-product-image {
		border-radius: 15px;
		max-height: 200px;
	}

	.edit-image-placeholder-container {
		border-radius: 15px;
		height: 200px;
		min-width: 200px;
		background-color: #e3e3e3;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.edit-user-image-placeholder-container {
		border-radius: 50%;
		height: 200px;
		width: 200px;
		background-color: #e3e3e3;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.edit-view-placeholder-container {
		height: calc(100% - 92px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: var(--secondary-accent);
		font-weight: 500;
		font-size: 20px;
	}

	.add-edit-user-image {
		width: 200px;
		height: 200px;
		border: 2px solid var(--primary);
		border-radius: 50%;
		object-fit: cover;
	}

	.grid-products {
		display: grid;
		padding-top: 0.5rem;
		grid-gap: 3.5rem;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		padding-left: 0px;
		padding-right: 0px;
		justify-items: center;
		align-items: center;
		max-width: 1140px;
		margin-left: auto;
		margin-right: auto;
	}

	.product-image-container {
		min-width: 150px;
		height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.custom-product-card,
	.grid-custom-product-card {
		width: 340px !important;
		border: none !important;
		border-radius: 20px !important;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
	}

	.custom-image-product-card,
	.grid-custom-image-product-card {
		height: 340px !important;
		width: 340px !important;
		border-top-left-radius: 20px !important;
		border-top-right-radius: 20px !important;
	}

	.custom-body-product-card,
	.grid-custom-body-product-card {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		padding: 0.5rem 1.25rem !important;
		height: 70px !important;
		background-color: var(--secondary-accent) !important;
		border: none !important;
		border-bottom-left-radius: 20px !important;
		border-bottom-right-radius: 20px !important;
	}

	.product-title {
		width: 100%;
		display: inline-block;
		color: #fff;
		font-size: 18px;
		font-weight: 500;
	}

	.my-account-desktop {
		display: flex;
	}

	.my-account-mobile {
		display: none;
	}

	.end-data-list {
		padding-bottom: 0.5rem;
		text-align: center;
		color: var(--secondary);
	}

	.product-image {
		border-radius: 15px;
		height: 150px;
		width: 150px;
	}

	.directions {
		display: grid;
		gap: 1rem;
		grid-template-columns: 60% 1fr;
		align-items: center;
		padding-top: 15px;
	}

	.direction {
		line-height: 20px;
	}

	.catalogue-filter-grid {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: repeat(100, 1fr);
		overflow-x: scroll;
		scroll-snap-type: x proximity;
		max-width: 1108px;
		margin: 16px auto;
	}

	.catalogue-filter-grid::-webkit-scrollbar {
		display: none;
	}

	.catalogue-filter-grid::after {
		content: '';
		width: 0.5rem;
		height: 1rem;
	}

	.direcctions-container {
		max-width: 600px;
	}

	.metric-container {
		position: relative;
		height: 100%;
	}

	.loading-layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: rgba(0, 0, 0, 0.2);
		color: var(--secondary-accent);
		font-weight: 500;
	}

	.all-metrics-container {
		display: flex;
		height: 100%;
	}

	.metrics-chart-container {
		width: 75%;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
	}

	.metrics-container {
		max-height: calc(100% - 92px);
		height: 100%;
	}

	.important-metrics-container {
		display: flex;
		justify-content: space-between;
		height: 18%;
	}

	.important-metrics-card {
		width: 90%;
		height: 72px;
		display: flex;
		align-items: center;
		padding: 12px 10px;
		border-radius: 15px;
		box-shadow: 0px 2px 4px rgb(0 0 0 / 10%), 0px 2px 4px rgb(0 0 0 / 15%);
	}

	.important-metrics-icon-wrapper {
		height: 50px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		background-color: #e3e3e3;
	}

	.important-metrics-btn {
		background-color: rgba(217, 0, 18, 0.2);
		width: 80px;
		height: 30px;
		border-radius: 8px;
		color: var(--secondary-accent);
		border: none;
		font-weight: 500;
		font-size: 15px;
		transition: 0.2s ease;
	}

	.important-metrics-btn:hover {
		background-color: var(--secondary-accent);
		color: #fff;
	}

	.chart-container {
		height: 85%;
		padding-top: 12px;
	}

	.cards-column {
		width: 25%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.clicks-metrics-card {
		display: flex;
		align-items: center;
		width: 100%;
		height: 14%;
		padding: 12px;
		box-shadow: 0px 2px 4px rgb(0 0 0 / 10%), 0px 2px 4px rgb(0 0 0 / 15%);
		border-radius: 15px;
	}

	.metrics-card-data-wrapper {
		width: 100%;
		text-align: center;
	}

	.clicks-card-title {
		font-size: 14px;
		display: inline-block;
		margin-bottom: 0px;
		font-weight: 500;
	}

	.important-clicks-card-title {
		width: 100%;
		font-size: 12px;
		display: inline-block;
		margin-bottom: 0px;
		font-weight: 500;
	}

	.metrics-data {
		align-items: center;
		justify-content: space-evenly;
		display: flex;
		margin-top: 0.5rem;
		font-weight: 500;
	}

	.percentage-data {
		display: flex;
		align-items: center;
		color: var(--secondary);
		margin-left: 5px;
	}

	.percentage-data-down {
		display: flex;
		align-items: center;
		color: var(--primary);
		margin-left: 5px;
	}

	.percentage-data-up {
		display: flex;
		align-items: center;
		color: var(--success);
		margin-left: 5px;
	}

	.percentage {
		margin-bottom: 0px;
		font-size: 12px;
		margin-left: 5px;
	}

	.phone-wrapper {
		background-color: var(--secondary);
		color: #fff;
		border-radius: 50px;
		height: 50px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.map-wrapper {
		background-color: rgb(113, 9, 145);
		color: #fff;
		border-radius: 50px;
		height: 50px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.whatsapp-clicks-wrapper {
		background-color: #075e54;
		color: #fff;
		border-radius: 50px;
		height: 50px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.chart-header {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 0.5rem;
	}

	.chart-title {
		margin-bottom: 0px;
		font-weight: 600;
		color: var(--secondary-accent);
	}

	.filter-btn {
		width: 142px !important;
		background-color: var(--secondary-accent) !important;
		border: none !important;
		transition: 0.3s ease;
	}

	.filter-btn:hover,
	.filter-btn:active {
		background-color: var(--primary) !important;
	}

	.filter-btn:focus {
		box-shadow: 0 0 0 0.2rem rgb(119 0 10 / 50%) !important;
	}

	.dropdown-toggle {
		border-radius: 10px !important;
	}

	.dropdown-item {
		padding: 0.25rem 1rem !important;
	}

	.custom-dropdown-item:active {
		background-color: rgba(217, 0, 18, 0.2) !important;
		color: var(--secondary-accent) !important;
	}

	.custom-dropdown-menu {
		width: 140% !important;
		transform: translate(-57px, 33px) !important;
		box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
			0 1px 10px 0 rgb(0 0 0 / 12%) !important;
		border: none !important;
	}

	.dropdownn-item-active,
	.dropdownn-item-active:active {
		background-color: rgba(217, 0, 18, 0.2) !important;
		color: var(--secondary-accent) !important;
	}

	.dropdown-options {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
