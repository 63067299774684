/* iPhone SE width */
@media (max-width: 320px) {
	.product-image-container {
		min-width: 110px;
		height: 110px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.product-image {
		border-radius: 15px;
		height: 110px;
		width: 110px;
	}

	.custom-modal-title {
		font-size: 20px !important;
	}

	.horizontal-scroll-cards {
		display: grid;
		grid-gap: 5px;
		grid-template-columns: repeat(4, 250px);
		overflow-x: scroll;
		scroll-snap-type: x proximity;
	}

	.horizontal-scroll-cards::-webkit-scrollbar {
		display: none;
	}

	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}

	.horizontal-scroll-cards::after {
		content: '';
		width: 0.5rem;
		height: 1rem;
	}

	.custom-image-product-card {
		height: 240px !important;
		width: 240px !important;
		border-top-left-radius: 20px !important;
		border-top-right-radius: 20px !important;
	}

	.custom-body-product-card {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		padding: 0.5rem 1.25rem !important;
		height: 70px !important;
		background-color: var(--secondary-accent) !important;
		border: none !important;
		border-bottom-left-radius: 20px !important;
		border-bottom-right-radius: 20px !important;
	}

	/* Grid Catalogue view */
	.grid-custom-product-card {
		width: 140px !important;
		border: none !important;
		border-radius: 20px !important;
		cursor: pointer;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
	}

	.grid-custom-image-product-card {
		height: 140px !important;
		width: 140px !important;
		border-top-left-radius: 20px !important;
		border-top-right-radius: 20px !important;
	}

	.grid-custom-body-product-card {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		padding: 0.25rem 1.25rem !important;
		height: 45px !important;
		font-size: 12px;
		background-color: var(--secondary-accent) !important;
		border: none !important;
		border-bottom-left-radius: 15px !important;
		border-bottom-right-radius: 15px !important;
	}
}

/* Galaxy S9 width */
@media only screen and (min-width: 321px) and (max-width: 360px) {
	/* Grid Catalogue view */
	.grid-custom-product-card {
		width: 150px !important;
		border: none !important;
		border-radius: 20px !important;
		cursor: pointer;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
	}

	.grid-custom-image-product-card {
		height: 150px !important;
		width: 150px !important;
		border-top-left-radius: 20px !important;
		border-top-right-radius: 20px !important;
	}

	.grid-custom-body-product-card {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		padding: 0.25rem 1.25rem !important;
		height: 45px !important;
		font-size: 12px;
		background-color: var(--secondary-accent) !important;
		border: none !important;
		border-bottom-left-radius: 15px !important;
		border-bottom-right-radius: 15px !important;
	}
}

/* iPhone X width */
@media only screen and (min-width: 361px) and (max-width: 375px) {
	/* Grid Catalogue view */
	.grid-custom-product-card {
		width: 165px !important;
		border: none !important;
		border-radius: 20px !important;
		cursor: pointer;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
	}

	.grid-custom-image-product-card {
		height: 165px !important;
		width: 165px !important;
		border-top-left-radius: 20px !important;
		border-top-right-radius: 20px !important;
	}

	.grid-custom-body-product-card {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		padding: 0.25rem 1.25rem !important;
		height: 45px !important;
		font-size: 12px;
		background-color: var(--secondary-accent) !important;
		border: none !important;
		border-bottom-left-radius: 15px !important;
		border-bottom-right-radius: 15px !important;
	}
}

@media only screen and (min-width: 321px) and (max-width: 767px) {
	.product-image-container {
		min-width: 150px;
		height: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.product-image {
		border-radius: 15px;
		height: 150px;
		width: 150px;
	}

	.horizontal-scroll-cards {
		display: grid;
		grid-gap: 5px;
		grid-template-columns: repeat(4, 280px);
		overflow-x: scroll;
		scroll-snap-type: x proximity;
	}

	.horizontal-scroll-cards::-webkit-scrollbar {
		display: none;
	}

	.horizontal-scroll-cards::after {
		content: '';
		width: 0.5rem;
		height: 1rem;
	}

	.custom-product-card {
		width: 260px !important;
		border: none !important;
		border-radius: 20px !important;
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16), 0px 3px 6px rgba(0, 0, 0, 0.23);
	}

	.custom-image-product-card {
		height: 260px !important;
		width: 260px !important;
		border-top-left-radius: 20px !important;
		border-top-right-radius: 20px !important;
	}

	.custom-body-product-card {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		padding: 0.5rem 1.25rem !important;
		height: 70px !important;
		background-color: var(--secondary-accent) !important;
		border: none !important;
		border-bottom-left-radius: 20px !important;
		border-bottom-right-radius: 20px !important;
	}
}

@media only screen and (min-width: 376px) and (max-width: 766px) {
	/* Grid Catalogue view */
	.grid-custom-image-product-card {
		height: 180px !important;
		width: 180px !important;
		border-top-left-radius: 20px !important;
		border-top-right-radius: 20px !important;
	}

	.grid-custom-body-product-card {
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		padding: 0.25rem 1.25rem !important;
		height: 45px !important;
		font-size: 12px;
		background-color: var(--secondary-accent) !important;
		border: none !important;
		border-bottom-left-radius: 15px !important;
		border-bottom-right-radius: 15px !important;
	}
}

@media (max-width: 767px) {
	.menu-shape {
		clip-path: polygon(
			0% 0%,
			100% 0%,
			100% 60%,
			75% 60%,
			70% 100%,
			20% 100%,
			20% 60%,
			0% 60%
		);
	}

	.custom-nav-link {
		padding: 5px 15px;
		margin: 3px 0px;
		display: flex;
		align-items: center;
		border-radius: 20px;
		color: rgba(255, 255, 255, 0.5);
		line-height: 1.3;
		background-color: transparent;
		cursor: pointer;
	}

	.active-nav-link {
		background-color: transparent;
		font-weight: 600;
		color: #fff;
	}

	.brand-custom-class {
		left: 10%;
	}

	.brand-title {
		font-size: 20;
		font-weight: 500;
	}

	.menu-toggle-btn {
		position: absolute;
		top: 10px;
		right: 10px;
		height: 40px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 10px;
		border: 1px solid rgba(255, 255, 255, 0.8);
		color: #fff;
	}

	.side-menu-option {
		padding: 0.75rem 1.25rem;
		border: 1px solid rgba(0, 0, 0, 0.125);
		color: var(--secondary);
	}

	.side-menu-option-active {
		background-color: var(--secondary-accent) !important;
		color: #fff !important;
		font-weight: 600;
		border-color: none !important;
		border: none !important;
	}

	.hero-trasparent-layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			180deg,
			rgba(255, 255, 255, 0.7) 0%,
			rgba(255, 255, 255, 0.45) 67.5%,
			rgba(255, 255, 255, 0) 76.15%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	.hero-cta-container {
		max-width: 100vw;
		height: 100%;
	}

	.home-hero-title {
		font-size: 30px;
		line-height: 35px;
		color: var(--secondary);
		font-weight: bold;
		max-width: 100%;
	}

	.atentention-image {
		max-width: 100%;
		border-radius: 10px;
	}

	.parallax-title {
		color: #fff;
		font-size: 2.6rem;
		font-weight: 500;
		margin: 0 20px;
	}

	.footer-custom-nav-link {
		width: 123px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px 15px;
		margin-right: 10px;
		border-radius: 20px;
		color: #fff;
		line-height: 1.3;
		background-color: #262626;
		cursor: pointer;
	}

	.about-hero-background-layer {
		width: 100%;
		height: 550px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: linear-gradient(
			214.49deg,
			#000000 7.5%,
			rgba(0, 0, 0, 0.88) 29.43%,
			rgba(0, 0, 0, 0.65) 49.44%,
			rgba(0, 0, 0, 0.27) 67.9%,
			rgba(196, 196, 196, 0) 81.37%
		);
	}

	.our-story-title {
		font-size: 35px;
		color: #fff;
		text-align: center;
		font-weight: bold;
		line-height: 50px;
		text-transform: uppercase;
	}

	.mision-vision-wrapper {
		height: 100%;
		margin-top: -10px;
		display: flex;
		align-items: flex-end;
	}

	.mision-vision-container {
		width: 100%x;
		min-height: 300px;
		max-width: 100%;
		padding: 20px;
		display: flex;
		justify-content: center;
		background: linear-gradient(
			180deg,
			rgba(220, 220, 220, 0) 0%,
			rgba(220, 220, 220, 0.5) 55.73%,
			rgba(220, 220, 220, 0.7) 79.17%,
			rgba(220, 220, 220, 1) 100%
		);
		filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.15));
		backdrop-filter: blur(6px);
		border-radius: 25px;
	}

	.mision-vision-separator {
		height: 1px;
		width: 200px;
		background-color: var(--secondary);
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.valores-grid-scroll {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: repeat(4, 200px);
		overflow-x: scroll;
		scroll-snap-type: x proximity;
	}

	.valores-grid-scroll::-webkit-scrollbar {
		display: none;
	}

	.valores-grid-scroll::after {
		content: '';
		width: 0.1rem;
		height: 1rem;
	}

	.hero-catalogue-layer {
		width: 100%;
		height: 100%;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.5) 45.31%,
			rgba(0, 0, 0, 0.7) 100%
		);
		display: flex;
		align-items: flex-end;
		padding: 10px;
	}

	.hero-catalogue-title {
		text-transform: uppercase;
		color: #fff;
		text-align: center;
		font-size: 35px;
	}

	.ais-SearchBox {
		width: 100%;
		max-width: 300px;
		margin: 20px auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: sticky;
		top: 130px;
	}

	.search-container {
		position: sticky;
		top: 50px;
		z-index: 4;
		background-color: rgba(255, 255, 255, 0.85);
		height: 125px;
	}

	.products-grid-scroll-cards {
		display: grid;
		grid-gap: 2rem;
	}

	.login-title {
		text-align: center;
		color: #fff;
		margin-top: 80px;
		margin-bottom: 50px;
	}

	.left-side-login {
		display: none;
	}

	.login-form-container {
		width: 100vw;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 45px 15px 0px 15px;
	}

	.modal-content {
		position: relative !important;
		display: flex !important;
		flex-direction: column !important;
		max-width: 500px !important;
		pointer-events: auto !important;
		background-color: #fff !important;
		background-clip: padding-box !important;
		border: none !important;
		border-radius: 20px !important;
		outline: 0 !important;
	}

	.custom-modal-header {
		background-color: var(--primary);
		color: #fff;
		border-top-left-radius: calc(20px - 2px) !important;
		border-top-right-radius: calc(20px - 2px) !important;
		padding: 0.75rem 1rem !important;
	}

	.Toastify__toast-container {
		width: 100vw;
		left: 0;
		margin: 0;
		padding: 10px !important;
	}

	.edit-view-placeholder-container {
		height: calc(100% - 92px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		color: var(--secondary-accent);
		font-weight: 500;
		font-size: 20px;
	}

	.grid-products {
		display: grid;
		grid-gap: 1rem;
		padding-top: 0.5rem;
		grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
		padding-left: 0px;
		padding-right: 0px;
		justify-items: center;
		align-items: center;
	}

	.product-title {
		width: 100%;
		display: inline-block;
		color: #fff;
		font-weight: 500;
	}

	.my-account-desktop {
		display: none;
	}

	.my-account-mobile {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 20px;
	}

	.end-data-list {
		text-align: center;
		color: var(--secondary);
	}

	.directions {
		display: grid;
		gap: 0.75rem;
		grid-template-columns: 2fr 1.5fr;
		padding-top: 15px;
		align-items: center;
	}

	.direction {
		font-size: 14px;
		line-height: 16px;
	}

	.catalogue-filter-grid {
		display: grid;
		grid-gap: 10px;
		grid-template-columns: repeat(11, 1fr);
		overflow-x: scroll;
		scroll-snap-type: x proximity;
		max-width: 1108px;
		margin: 14px auto;
		padding: 0 16px;
	}

	.catalogue-filter-grid::-webkit-scrollbar {
		display: none;
	}

	.catalogue-filter-grid::after {
		content: '';
		width: 0.5rem;
		height: 1rem;
	}

	.truncated-text {
		text-align: center;
		line-height: 1rem;
	}
}
